import { defineMessages } from 'utils/intl';

export default defineMessages({
  configuration: 'Konfiguracja',
  more: 'Dowiedz się więcej',
  configurationDescription: `Dajemy Ci do dyspozycji nasz autorski konfigurator, który umożliwia skorzystanie z pełnej oferty Dell.
    Zbuduj konfigurację samodzielnie lub skontaktuj się telefonicznie i skorzystaj z pomocy naszych specjalistów!`,
  configurationLongDescription: '',
  pricing: 'Wycena',
  pricingDescription: `Razem z Dell przygotujemy dla Ciebie indywidualną wycenę.
    Najwyższy status partnera Dell pozwala nam na zaproponowanie najlepszej możliwej ceny na rynku.`,
  pricingLongDescription: '',
  production: 'Produkcja',
  productionDescription: `Twoje zamówienie zostanie zrealizowane specjalnie dla Ciebie poprzez oficjalny kanał dystrybucji Dell w Polsce
    - dzięki temu zyskujesz pełną gwarancję pochodzenia.`,
  productionLongDescription: '',
  shipment: 'Dostawa',
  shipmentDescription: `Większość urządzeń jest produkowana w fabryce Dell w Łodzi -
    pozwala nam to na maksymalne skrócenie łańcucha dostaw i realizację zamówień w najkrótszym możliwym czasie.`,
  shipmentLongDescription: '',
  implementation: '{bold} (opcjonalne)',
  implementationBold: 'Wdrożenie',
  implementationDescription: `Certyfikowany zespół inżynierów i 30-letnie doświadczenie na rynku pozwala zaoferować usługi wdrożeniowe
    poczynając od pojedynczych instalacji po najbadziej rozbudowane i zaawansowane rozwiązania.`,
  implementationLongDescription: '',
});
